<template>
  <v-navigation-drawer app fixed permanent width="300" class="#fff">
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar size="42">
          <img src="/logo.jpeg" alt="Logo" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="text--logo mt-1" style="font-size: 18px">
            {{ client.business_name }}
          </v-list-item-title>

          <v-list-item-subtitle class="body-2">
            Administrators
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list dense subheader>
      <template v-for="(links, submenu) in sidebar">
        <v-subheader :key="`submenu-${submenu}`" class="caption mt-4 mx-2">
          {{ submenu }}
        </v-subheader>

        <template v-for="(link, index) in links">
          <v-list-item
            v-if="!link.children"
            :key="`main-${submenu}-${index}`"
            :to="`/${link.to}`"
          >
            <v-list-item-icon>
              <v-icon small>mdi-{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title class="body-2">
              {{ link.name }}
            </v-list-item-title>
          </v-list-item>

          <v-list-group v-else no-action :key="`main-${index}`">
            <template v-slot:activator>
              <v-list-item-icon>
                <v-icon small>mdi-{{ link.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="body-2">
                {{ link.name }}
              </v-list-item-title>
            </template>

            <v-list-item
              v-for="(child, i) in link.children"
              :key="`sub-${i}`"
              :to="`/${child.to}`"
            >
              <v-list-item-content>
                <v-list-item-title>
                  {{ child.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      menu: {
        'ANALYTICS': [
          { name: 'Suppliers', icon: 'account-group', to: 'iam/suppliers', permission: 'iam.users.index' },
          { name: 'Farmers', icon: 'account-group', to: 'iam/farmers', permission: 'iam.users.index' },
          {
            name: 'Dashboard',
            icon: 'chart-bar',
            to: 'iam/partnerships',
            permission: 'iam.partnerships.index',
          },
          {
            name: 'Reports',
            icon: 'microsoft-excel',
            to: 'insurance/orders',
            permission: 'insurance.orders.index',
          },
        ],

        'SYSTEM CONFIGURATIONS': [
          {
            name: 'Locations',
            icon: 'earth',
            to: 'nchi/jurisdictions',
            permission: 'nchi.jurisdictions.index',
          },
          {
            name: 'Ussd suppliers',
            icon: 'store-search-outline',
            to: 'ussd/suppliers',
            permission: 'nchi.jurisdictions.index',
          },
          {
            name: 'Paybill Configurations',
            icon: 'cash-check',
            to: 'paybill/configurations',
            permission: 'iam.permissions.index',
          },

          // {
          //   name: 'Insurance',
          //   icon: 'handshake',
          //   children: [
          //     { name: 'Products', to: 'insurance/products', permission: 'insurance.products.index' },
          //     { name: 'Reference units', to: 'insurance/reference-units', permission: 'insurance.reference-units.index' },
          //     { name: 'Reference indices', to: 'insurance/reference-indices', permission: 'insurance.reference-indices.index' },
          //   ],
          // },

          {
            name: 'Retail & distribution',
            icon: 'package-variant',
            children: [
              {
                name: 'Products',
                to: 'retail/products',
                permission: 'bidhaa.products.index',
              },
              // { name: 'Brands', to: 'bidhaa/brands', permission: 'bidhaa.products.index' },
              // { name: 'Metrics', to: 'bidhaa/metrics', permission: 'bidhaa.products.index' },
            ],
          },

          {
            name: 'Farm resources',
            icon: 'compost',
            to: 'silo/resources',
            permission: 'silo.resources.index',
          },
        ],

        'ACCESS CONTROL': [
          {
            name: 'Roles',
            icon: 'account-lock',
            to: 'roles',
            permission: 'iam.roles.index',
          },
          {
            name: 'Users',
            icon: 'account-group',
            to: 'users',
            permission: 'iam.users.index',
          },
          {
            name: 'Permissions',
            icon: 'lock',
            to: 'iam/permissions',
            permission: 'iam.permissions.index',
          },
        ],
      },
    }
  },

  computed: {
    sidebar() {
      for (let sidemenu in this.menu) {
        const links = this.menu[sidemenu]

        this.menu[sidemenu] = links.filter((link, index) => {
          if (link.children) {
            this.menu[sidemenu][index]['children'] = this.menu[sidemenu][
              index
            ].children.filter((child) => {
              return vault.can(child.permission)
            })
            return this.menu[sidemenu][index]['children'].length !== 0
          } else {
            return vault.can(link.permission)
          }
        })

        if (this.menu[sidemenu].length === 0) {
          delete this.menu[sidemenu]
        }
      }

      return this.menu
    },

    client() {
      return vault.take('client')
    },
  },
}
</script>
